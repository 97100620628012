const hostname = window && window.location && window.location.hostname;

let backendHost;
let sttHost;

if (hostname === 'chiefofstaffhq.com') {
  backendHost = 'https://chiefofstaffhq.com';
  sttHost = 'https://stt.chiefofstaffhq.com';
} else if (hostname === 'staging.chiefofstaffhq.com') {
  backendHost = 'https://staging.chiefofstaffhq.com';
  sttHost = 'https://stt.chiefofstaffhq.com';
} else if (hostname === 'chiefofstaffhq.ngrok.io') {
  backendHost = 'http://chiefofstaffhq.ngrok.io';
  sttHost = 'NO_HOST_SET';
} else {
  backendHost = 'http://localhost:8000';
  // sttHost = 'http://localhost:9000';
  sttHost = 'https://stt.chiefofstaffhq.com';
}
console.log('backendHost: ' + backendHost);
console.log('sttHost: ' + sttHost);

export const API_ROOT = `${backendHost}`;
export const STT_API_ROOT = `${sttHost}`;