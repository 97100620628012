import {
  getReadingPreSignedUrlPlaybackStatusViewPath,
  setReadingPreSignedUrlPlaybackStatusViewPath
} from '../readings/fetchHooks.js';
import Plyr from 'plyr';

export default class CosPlayer {
  constructor() {
    // Set player up as singlton
    if (CosPlayer._instance) {
      throw new Error("Attempt to instantiate more than one singleton");
    }
    CosPlayer._instance = this;

    // Initialize key variables
    this.audioElId = null;
    this.cardEl = null;
    this.preSignedUrlId = null;
    this.startTime = null;

    this.player = null;
    this.playbackTimeCheckInterval = 3;
    this.playbackTime = 0;
  }

  destroyPlayer() {
    const playbackControlsEl = this.cardEl.getElementsByClassName('article-card-icons-playback-controls')[0];
    this.player.destroy();
    playbackControlsEl.remove();

    const actionIconEl = this.cardEl.getElementsByClassName('fa-spinner')[0];
    this.cardEl.getElementsByClassName('article-card-icons-play')[0].hidden = false;
    const articleMarkReadDivEl = actionIconEl.parentNode.parentNode.querySelector('.article-card-icons-mark-read ');

    // UI update pulled out of setStateSynthTaskLoadingButtonStyle
    actionIconEl.parentNode.classList.toggle('loading');
    actionIconEl.classList.toggle('fa-play');
    actionIconEl.classList.toggle('fas');
    actionIconEl.classList.toggle('fad');
    actionIconEl.classList.toggle('fa-spinner');
    actionIconEl.classList.toggle('fa-pulse');
    actionIconEl.setAttribute('title', 'One moment...');
    articleMarkReadDivEl.classList.toggle('p-r-15');
    articleMarkReadDivEl.classList.toggle('p-r-5');

    this.player = null;
  }

  currentTimeRounded() {
    return Math.round(this.player.currentTime);
  }

  async savePlaybackTime() {
    if (!this.playbackPublic) {
      await setReadingPreSignedUrlPlaybackStatusViewPath(this.preSignedUrlId, this.currentTimeRounded());
    }
  }

  async retrievePlaybackTime() {
    if (!this.playbackPublic) {
      const playbackTimeObj = await getReadingPreSignedUrlPlaybackStatusViewPath(this.preSignedUrlId);
      this.startTime = playbackTimeObj.playbackPosition;
      return playbackTimeObj.playbackPosition;
    }
  }

  addListeners() {
    // Listen for audio begins to play
    this.player.on('play', _ => {
      // TODO: instrument this event
    });
    // Listen for playback time changes
    this.player.on('timeupdate', _ => {
      const readyToUpdate = () => {
        // Set how often to send updates
        const currentTime = this.player.currentTime;
        if ((currentTime - this.playbackTime) > this.playbackTimeCheckInterval) {
          this.playbackTime = currentTime;
          return true;
        } else {
          return false;
        }
      };
      if (readyToUpdate()) {
        this.savePlaybackTime().catch(error => {console.error(error);});
        const progress = parseInt(String((this.player.currentTime / this.player.duration) * 100), 10) + "%";
        console.log(`Playback is at: ${this.currentTimeRounded()} seconds, ${progress} complete.`);
      }
    });
    // The first time played, resume playback if possible
    this.player.on('seeked', () => {
      this.playbackTime = this.player.currentTime;
    });
  }

  async initPlayer() {
    this.addListeners();
    this.player.currentTime = this.retrievePlaybackTime();
    return this.retrievePlaybackTime();
  }

  async start(audioElId, articlePlayDivEl, articleCardEl, preSignedUrlId, readingPlayerPlaybackPublic) {
    if (this.player === null) {
      this.audioElId = audioElId;
      this.cardEl = articleCardEl;
      this.preSignedUrlId = preSignedUrlId;
      this.playbackPublic = readingPlayerPlaybackPublic === true;
      this.player = new Plyr(this.audioElId);

      this.initPlayer().then((startTime) => {
        if (startTime > 0) {
          console.log(`Resuming playback from ${startTime} seconds`);
        }
        this.player.play();
        // This time setting is done for desktop chrome / firefox
        this.player.currentTime = startTime;
        // This time setting is done for safari / iOS webkit
        this.player.once('canplay', () => {
          this.player.currentTime = startTime;
        });
      });
    } else {
      this.savePlaybackTime().catch(error => {console.error(error);});
      this.destroyPlayer();

      this.start(audioElId, articlePlayDivEl, articleCardEl, preSignedUrlId, readingPlayerPlaybackPublic)
        .catch(error => {console.error(error);});
    }
  }
}
