import {getCookie} from './authUtils';

export const postData = async (url, data) => {
  const csrfToken = getCookie('csrftoken');
  return await fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'X-CSRFToken': csrfToken,
      "Accept": "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  });
};

export const postFileData = async (url, data) => {
  const csrfToken = getCookie('csrftoken');
  return await fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'X-CSRFToken': csrfToken,
    },
    body: data
  });
};

export const postAudioData = async (url, audioBlob, modelVersion) => {
  const csrfToken = getCookie('csrftoken');
  let formData = new FormData();
  formData.append('audio_file', audioBlob);
  formData.append('gpt_version', modelVersion);

  return await fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'X-CSRFToken': csrfToken,
      "Accept": "application/json"
    },
    body: formData
  });
};

export const promisePoll = (promiseFunction, { pollIntervalMs = 3100 } = {}) => {
  const startPoll = async resolve => {
    const startTime = new Date();
    const result = await promiseFunction();

    if (result) return resolve();

    const timeUntilNext = Math.max(pollIntervalMs - (new Date() - startTime), 0);
    setTimeout(() => startPoll(resolve), timeUntilNext);
  };

  return new Promise(startPoll);
};
