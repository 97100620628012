import CosPlayer from './cosPlayer.js';
import {
  createSpeechSynthTask,
  getReadingPreSignedPublicUrl,
  getReadingSynthTaskStatus
} from '../readings/fetchHooks.js';
import {promisePoll} from '../core/fetchUtils.js';
import {testWebAccessibility} from '../core/authUtils.js';
import {Notify} from 'notiflix/build/notiflix-notify-aio';

const readingPlayer = new CosPlayer;
/**
 * These functions handle all UI behavior and calls to
 * backend fetch functions for the Briefings list views
 */


/**
 * Briefing action buttons
 */

const setStateSynthTaskLoadingButtonStyle = (synthTaskState, actionIconEl) => {
  if (synthTaskState === 'RESTORE_READY_TO_SYNTH') { // Set icon to loading state
    actionIconEl.parentNode.classList.toggle('loading');
    actionIconEl.classList.toggle('fa-headphones');
    actionIconEl.classList.toggle('fa-spinner');
    actionIconEl.classList.toggle('fa-pulse');
    actionIconEl.setAttribute('title', 'Generate an audio reading of this article');
  }
  if (synthTaskState === 'LOADING_TTS') { // Set icon to loading state
    actionIconEl.parentNode.classList.toggle('loading');
    actionIconEl.classList.toggle('fa-headphones');
    actionIconEl.classList.toggle('fa-spinner');
    actionIconEl.classList.toggle('fa-pulse');
    actionIconEl.setAttribute('title', 'Synthesizing audio of this article...');
  } else if (synthTaskState === 'LOADING_PLAYBACK' || synthTaskState === 'UNLOAD_PLAYBACK') { // Show loading icon in case js is slow
    actionIconEl.parentNode.classList.toggle('loading');
    actionIconEl.classList.toggle('fa-play');
    actionIconEl.classList.toggle('fas');
    actionIconEl.classList.toggle('fad');
    actionIconEl.classList.toggle('fa-spinner');
    actionIconEl.classList.toggle('fa-pulse');
    actionIconEl.setAttribute('title', 'One moment...');
  } else if (synthTaskState === 'COMPLETE') { // Set icon to play state
    actionIconEl.parentNode.classList.toggle('loading');
    actionIconEl.parentNode.classList.toggle('article-card-icons-synth-audio');
    actionIconEl.parentNode.classList.toggle('article-card-icons-play');
    actionIconEl.classList.toggle('fa-pulse');
    actionIconEl.classList.toggle('fa-spinner');
    actionIconEl.classList.toggle('fad');
    actionIconEl.classList.toggle('fas');
    actionIconEl.classList.toggle('fa-play');
    actionIconEl.setAttribute('title', 'Listen to this article');
  }
};

const addPollingForCompletedSynthTask = async (readingId) => {
  /**
   *  Use polling wrapper to regularly check for the status
   *  of a TTS synthsesis task if it is completed, update interface accordingly
   */
  await promisePoll(async () => {
    // Request current status of TTS task
    const taskStatus = await getReadingSynthTaskStatus(readingId);
    if (taskStatus.synthStatus === 'CO') { // if the task is complete
      // Select the loading icon associated with the reading undergoing TTS synthesis
      const cardEl = document.querySelector(`[data-reading-id="${readingId}"]`);
      const actionIconEl = cardEl.querySelector("div.article-card-icons > div.article-card-icons-synth-audio > i");

      // Set icon to loading state
      setStateSynthTaskLoadingButtonStyle('COMPLETE', actionIconEl);
      // Add play widget display event listener for only this element
      actionIconEl.addEventListener('click', (event: Event) => {
        event.stopPropagation();
        const articlePlayDivEl = (event.currentTarget as HTMLElement).parentNode;
        playButtonsClickLogic(articlePlayDivEl);
      });

      // End polling wrapper
      return Promise.resolve(taskStatus);
    } else { // else status is something other than completed
      console.log('Audio synthesis in progress...');
    }
  });
};

/**
 * Reading synthesis
 */
const initSynthButtonsClickLogic = async (articlePlayDivEl) => {
  const articleId = articlePlayDivEl.closest('.card').dataset.objectId;
  const articleCardEl: HTMLElement = document.querySelector(`[data-object-id="${articleId}"]`);
  const articleSynthIconEl = articlePlayDivEl.querySelector('.fa-headphones');

  Notify.init({
    width: '400px',
    timeout: 6000,
    failure: {
      background: '#e63946ff',
    },
  });

  if ( // if the clicked element is the synthesize button, and is not yet loading
    articlePlayDivEl.classList.contains('article-card-icons-synth-audio')
    && !articlePlayDivEl.classList.contains('loading')
  ) {
    // Set visual style for loading state
    setStateSynthTaskLoadingButtonStyle('LOADING_TTS', articleSynthIconEl);

    const synthTaskResponse = async (articleId) => {
      /**
       * Wrapper for creating a tts synth task
       */
      const response = await createSpeechSynthTask(articleId);
      return await response.json();
    };

    // Kick off audio synthesis
    const response = await synthTaskResponse(articleId);

    if (response.status !== 'OK' && response.errorCode === 'tts_synthesis_quota_exceeded') {
      // Handle quota exceeded error
      const failureMessage = 'You have exceeded your audio synthesis quota. Please wait until your quota resets and try again.';
      console.log('TTS synthesis quota exceeded');
      setStateSynthTaskLoadingButtonStyle('RESTORE_READY_TO_SYNTH', articleSynthIconEl);
      Notify.failure(failureMessage);
    } else {
      // Update reading object id data attribute for the article
      articleCardEl.setAttribute('data-reading-id', response.ReadingId);

      // Poll for updated status
      const ignore = await addPollingForCompletedSynthTask(response.ReadingId);
      console.log('Synthesis complete');

      // Immediately fetch pre-signed url, write it to reading-id attribute for immediate use
      const readingUrlObject = await getReadingPreSignedPublicUrl(response.ReadingId);
      articleCardEl.dataset.readingUrl = readingUrlObject['preSignedUrl'];
      articleCardEl.dataset.preSignedUrlId = readingUrlObject['preSignedUrlId'];
    }
  } else if (articlePlayDivEl.parentNode.classList.contains('article-card-icons-play')) {
    // if state has already changed to ready for play, ignore further clicks
  }
};

const getPlayerId = (readingId): string => {
  /**
   * Return an element id based on recording's reading id
   */
  const idPrefix = 'player';
  return `${idPrefix}-${readingId}`;
};

const constructPlaybackWidget = (readingUrl, preSignedUrlId, readingId) => {
  /**
   * Customize html template snippet for use with a specific reading
   * app/templates/briefings/__article_card_icons.html
   */
  const audioPlaybackTemplateEl: HTMLElement = document.getElementById('audio-playback-template');
  const tmpl = (audioPlaybackTemplateEl as HTMLTemplateElement).content.cloneNode(true);
  (tmpl as HTMLElement).querySelector('.playback-source').setAttribute('src', readingUrl);
  (tmpl as HTMLElement).querySelector('#player').setAttribute('id', getPlayerId(readingId));
  const playerEl: HTMLElement = (tmpl as HTMLElement).querySelector('#' + getPlayerId(readingId));
  playerEl.dataset.preSignedUrl = preSignedUrlId;
  return tmpl;
};

const playButtonsClickLogic = async (articlePlayDivEl) => {
  const articleCardEl: HTMLElement = articlePlayDivEl.closest('.card');
  const readingId = articleCardEl.dataset.readingId; // dataset will always contain reading id
  const articlePlayIconEl = articlePlayDivEl.querySelector('.fa-play');
  const articleCardIconsEl = articlePlayDivEl.closest('.article-card-icons');

  setStateSynthTaskLoadingButtonStyle('LOADING_PLAYBACK', articlePlayIconEl);

  let preSignedUrl;
  let preSignedUrlId;
  // Find a valid pre-signed url to play
  if ('readingUrl' in articleCardEl.dataset) {
    // if a valid url exists in the card's dataset, use it (fast)
    const readingUrlAccessibleObject = await testWebAccessibility(articleCardEl.dataset.readingUrl);
    if (readingUrlAccessibleObject['corsError'] === false) {
      preSignedUrl = articleCardEl.dataset.readingUrl;
      preSignedUrlId = articleCardEl.dataset.preSignedUrlId;
    }
  } else { // else get a fresh pre-signed and use that (slow)
    const readingUrlObject = await getReadingPreSignedPublicUrl(readingId);
    preSignedUrl = readingUrlObject['preSignedUrl'];
    preSignedUrlId = readingUrlObject['preSignedUrlId'];
    articleCardEl.dataset.preSignedUrlId = preSignedUrlId;
    articleCardEl.dataset.readingUrl = preSignedUrl;
  }
  // Remove play button, adjust spacing on remaining buttons
  articlePlayDivEl.hidden = true;
  // Add audioconfigured audio tagmostly
  articleCardIconsEl.appendChild(constructPlaybackWidget(preSignedUrl, preSignedUrlId, readingId));

  const readingPlayerId = `#${getPlayerId(readingId)}`;
  const readingPlayerPlaybackPublic = Object.is(Boolean(document.body.dataset.isLanding), undefined) ? false : true;
  readingPlayer.start(readingPlayerId, articlePlayDivEl, articleCardEl, preSignedUrlId, readingPlayerPlaybackPublic)
    .catch(error => {
      console.error('Error during start of player:', error);
    });
};

/**
 * Generic document-wide, click add listener for all elements with matching classes
 */
const initButtonsClickListeners = (elementClassName, clickLogicFunction) => {
  /**
   * Generic function for assigning a function to run when any element with elementClassName is passed in
   */
  const elements: Element[] = Array.from(document.getElementsByClassName(elementClassName));
  for (let item of elements) {
    if (item.getAttribute('listener') !== 'true') {
      item.addEventListener('click', (e) => {
        e.stopPropagation();
        clickLogicFunction(e.currentTarget);
      });
      item.setAttribute('listener', 'true');
    }
  }
};

const initBriefingActionButtons = () => {
  initButtonsClickListeners('article-card-icons-synth-audio', initSynthButtonsClickLogic);
  initButtonsClickListeners('article-card-icons-play', playButtonsClickLogic);
};

const initPauseIfInterrupted = () => {
  const playbackButtonEl = document.getElementById('hear-sample-play');
  playbackButtonEl.addEventListener('click', (event) => {
    if (readingPlayer.player != undefined) {
      if (readingPlayer.player.playing) {
        readingPlayer.player.pause();
      }
    }
  });
};
/**
 * Main
 */
document.addEventListener("DOMContentLoaded", () => {
  initBriefingActionButtons();
  initPauseIfInterrupted();
});
