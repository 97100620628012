import { readingsApiUrl } from './routes.js';
import { postData } from '../core/fetchUtils.js';

export const getReadingSynthTaskStatus = async (readingId) => {
  /**
   * readings.api.views.ReadingSynthStatusView (viewset)
   */
  const response = await fetch(readingsApiUrl.getReadingSynthTaskStatus(readingId));
  return response.json();
};

export const createSpeechSynthTask = async (articleId) => {
  /**
   * readings.api.views.StartSpeechSynthTask (viewset)
   */
  return postData(readingsApiUrl.createSpeechSynthTask(), { 'article': articleId });
};

export const getReadingPreSignedUrl = async (readingId) => {
  /**
   *  readings.api.views.ReadingPreSignedUrlView
   */
  const response = await postData(readingsApiUrl.getReadingPreSignedUrlView(readingId));
  return response.json();
};

export const getReadingPreSignedPublicUrl = async (readingId) => {
  /**
   *  readings.api.views.ReadingPreSignedUrlView
   */
  const response = await postData(readingsApiUrl.getReadingPreSignedPublicUrlView(readingId));
  return response.json();
};

export const getReadingPreSignedUrlPlaybackStatusViewPath = async (preSignedUrlId) => {
  /**
   *  readings.api.views.ReadingPreSignedUrlPlaybackStatusView
   */
  const response = await fetch(readingsApiUrl.ReadingPreSignedUrlPlaybackStatusView(preSignedUrlId));
  return response.json();
};

export const setReadingPreSignedUrlPlaybackStatusViewPath = async (preSignedUrlId, playbackPosition) => {
  /**
   * readings.api.views.ReadingPreSignedUrlPlaybackStatusView
   */
  return postData(readingsApiUrl.ReadingPreSignedUrlPlaybackStatusView(preSignedUrlId), { 'playbackPosition': playbackPosition });
};