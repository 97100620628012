export const getCookie = (name) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    let cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

export const testWebAccessibility = async (urlToTest) => {
  /**
   * This script tests whether CORS permissions are correct on an S3 bucket or other resource.
   * A CORS error will show in terminal as `Uncaught DOMException: A network error occurred.`
   **/
  try {
    let response = await fetch(urlToTest);
    if (response.ok) { // if HTTP-status is 200-299
      return { 'responseOk': true, 'corsError': false };
    } else {
      console.log("This pre-signed url appears expired.");
      return { 'responseOk': false, 'corsError': false };
    }
  } catch (err) {
    if (err.toString().includes('NetworkError')) {
      return { 'responseOk': false, 'corsError': true };
    }
  }
};
