import { API_ROOT } from '../core/apiConfig.js';

export const readingsApiUrl = {
  apiPath: '/api/',
  bUrl() {return API_ROOT + this.apiPath;},

  // readings.api.views.ReadingViewSet (viewset)
  getReadingDetailPath: 'readings/',
  getReadingView(articleId) {
    return this.bUrl() + this.getReadingDetailPath + articleId + '/';
  },

  // readings.api.views.ReadingSynthStatusView (viewset)
  getReadingSynthTaskStatusPath: 'readings/',
  getReadingSynthTaskStatus(readingId) {
    return this.bUrl() + this.getReadingSynthTaskStatusPath + readingId + '/status/';
  },

  // readings.api.views.StartSpeechSynthTask (viewset)
  createSpeechSynthTaskPath: 'readings/create/',
  createSpeechSynthTask() {
    return this.bUrl() + this.createSpeechSynthTaskPath;
  },

  // readings.api.views.ReadingPreSignedUrlView
  getReadingPreSignedUrlViewPath: 'readings/',
  getReadingPreSignedUrlView(readingId) {
    return this.bUrl() + this.getReadingPreSignedUrlViewPath + readingId + '/url/';
  },

  // readings.api.views.ReadingPreSignedPublicUrlView
  getReadingPreSignedPublicUrlViewPath: 'readings/',
  getReadingPreSignedPublicUrlView(readingId) {
    return this.bUrl() + this.getReadingPreSignedPublicUrlViewPath + readingId + '/pub/url/';
  },

  // readings.api.views.ReadingPreSignedUrlPlaybackStatusView
  ReadingPreSignedUrlPlaybackStatusViewPath: 'readings/url/',
  ReadingPreSignedUrlPlaybackStatusView(preSignedUrlId) {
    return this.bUrl() + this.ReadingPreSignedUrlPlaybackStatusViewPath + preSignedUrlId + '/playback/';
  }
};